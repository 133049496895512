import React,{ useState } from "react"
import api from "../api/config"
const Reset = () =>{
    return(
<div className="nk-app-root">
  <div className="nk-main ">
    <div className="nk-wrap nk-wrap-nosidebar">
      <div className="nk-content ">
        <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <a href="/" className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src="assets/images/wg_logo.png"
                srcSet="assets/images/wg_logo.png 2x"
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src="assets/images/wg_logo.png"
                srcSet="assets/images/wg_logo.png 2x"
                alt="logo-dark"
              />
            </a>
          </div>
          <div className="card">
            <div className="card-inner card-inner-lg">
              <div className="nk-block-head">
                <div className="nk-block-head-content">
                  <h5 className="nk-block-title">Reset password</h5>
                  <div className="nk-block-des">
                    <p>
                      If you forgot your password, well, then we’ll email you
                      instructions to reset your password.
                    </p>
                  </div>
                </div>
              </div>
              <form >
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Email
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="default-01"
                      placeholder="Enter your email address"
                      required
                      autofocus
                    />
                  </div>
                </div>
                <div className="form-group">
                  <button className="btn btn-lg btn-primary btn-block" type="submit">
                    Send Reset Link
                  </button>
                </div>
              </form>
              <div className="form-note-s2 text-center pt-4">
                <a href="/">
                  <strong>Return to login</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    )

}
export default Reset