import React,{ useState } from "react"
import api from "../api/config"
const Auth = () => {
  //check if logged in
  const token = localStorage.getItem('token')
  if(!token){

  }else{
    window.location.replace("/dashboard")
  }
  //end check
  const [admin_email_address, setEmail] = useState('')
    const [admin_password, setPassword] = useState('')
    const handleSubmit = async (e) => {
      e.preventDefault()
      try {
          const response = await api.post('/api/login', { admin_email_address, admin_password })
          localStorage.setItem('token', response.data.token);
          window.location.href = '/dashboard'
      } catch (error) {
        alert(`Wrong Credentials ${error}`)
          console.error("Authentication error", error)
          // Handle error, show user feedback, etc.
      }
  }

        return(
            <div className="nk-app-root">
  <div className="nk-main ">
    <div className="nk-wrap nk-wrap-nosidebar">
      <div className="nk-content ">
        <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <a href="/" className="logo-link" title="Westgate Shopping Mall">
              <img
                className="logo-light logo-img logo-img-lg"
                src="./assets/images/wg_logo.png"
                srcSet="./assets/images/wg_logo.png "
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src="./assets/images/wg_logo.png"
                srcSet="./assets/images/wg_logo.png "
                alt="logo-dark"
              />
            </a>
          </div>
          <div className="card">
            <div className="card-inner card-inner-lg">
              <div className="nk-block-head">
                <div className="nk-block-head-content">
                  <h4 className="nk-block-title">Sign In</h4>
                  <div className="nk-block-des">
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Email or Username
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      value={admin_email_address} onChange={e => setEmail(e.target.value)} 
                      id="default-01"
                      placeholder="Enter your email address or username"
                      autofocus
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <a
                      className="link link-primary link-sm"
                      href="reset"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#"
                      className="form-icon form-icon-right passcode-switch lg"
                      data-target="password"
                    >
                      <em className="passcode-icon icon-show icon ni ni-eye" />
                      <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                    </a>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      value={admin_password} onChange={e => setPassword(e.target.value)}
                      id="password"
                      placeholder="Enter your password"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <button className="btn btn-lg btn-primary btn-block" type="submit">
                    Sign in
                  </button>
                </div>
              </form>
              
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>

        )
    }
export default Auth