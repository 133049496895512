import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import api from "../api/config"
import Header from "../partials/header"
import Navbar from "../partials/navbar"
import Footer from "../partials/footer"
function UserPoints() {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null)
  const [pointsData, setPointsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const userResponse = await api.get(`/api/user/${userId}`);
        setUserData(userResponse.data);

        // Second API call
        // Replace '/api/secondEndpoint' with your actual endpoint
        const pointsResponse = await api.get(`/api/points/${userId}`);
        setPointsData(pointsResponse.data);
        console.log(pointsData)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData()
  }, [userId])

  if (!userData) {
    return <div>not found</div>
  }
console.log(userData)
  return (
<div className="nk-app-root">
  <div className="nk-main ">
  <Header />
    <div className="nk-wrap ">
    <Navbar />
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title">{userData.customer} Points Overview</h3>
                  <div className="nk-block-des text-soft">
                    <p>Customer information</p>
                  </div>
                </div>
                <div className="nk-block-head-content">
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand me-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-more-v" />
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          <div className="drodown">
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                              data-bs-toggle="dropdown"
                            >
                              <em className="d-none d-sm-inline icon ni ni-calender-date" />
                              <span>
                                <span className="d-none d-md-inline">Last</span>{" "}
                                30 Days
                              </span>
                              <em className="dd-indc icon ni ni-chevron-right" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a href="#">
                                    <span>Last 30 Days</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span>Last 6 Months</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span>Last 1 Years</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="nk-block-tools-opt">
                          <a href="/users" className="btn btn-primary">
                            <em className="icon ni ni-arrow-left" />
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-block">
              <div className="row g-gs">
                <div className="col-xxl-7">
                  <div className="row g-gs">
                    <div className="col-lg-7 col-xxl-12">
                      <div className="card">
                        <div className="card-inner">
                          <div className="card-title-group align-start mb-2">
                            <div className="card-title">
                              <h6 className="title">Contact and Bio Information</h6>
                              <p>Personal information</p>
                            </div>
                            <div className="card-tools">
                              <em
                                className="card-hint icon ni ni-help-fill"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Revenue from subscription"
                              />
                            </div>
                          </div>
                          <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
                                <div className="nk-sale-data-group flex-md-nowrap g-10">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <th>Name:</th>
                                        <td>{userData.customer}</td>
                                      </tr>
                                      <tr>
                                        <th>Phone:</th>
                                        <td>{userData.phone}</td>
                                      </tr>
                                      <tr>
                                        <th>Email:</th>
                                        <td>{userData.email}</td>
                                      </tr>
                                      <tr>
                                        <th>Total Spend:</th>
                                        <td>KES {Math.round(((userData.rejectedPoints || 0) + (userData.currentPoints || 0)) * 1000)}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                            <div className="nk-sales-ck sales-revenue">
                              <canvas
                                className="sales-bar-chart"
                                id="salesRevenue"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-xxl-12">
                      <div className="row g-gs">
                        <div className="col-sm-6 col-lg-12 col-xxl-6">
                          <div className="card">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">
                                   Rejected points
                                  </h6>
                                </div>
                                <div className="card-tools">
                                  <em
                                    className="card-hint icon ni ni-help-fill"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Total active subscription"
                                  />
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">{userData.rejectedPoints||0}</span>
                                 
                                </div>
                                <div className="nk-sales-ck">
                                  <canvas
                                    className="sales-bar-chart"
                                    id="activeSubscription"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-12 col-xxl-6">
                          <div className="card">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Accepted points</h6>
                                </div>
                                <div className="card-tools">
                                  <em
                                    className="card-hint icon ni ni-help-fill"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Daily Avg. subscription"
                                  />
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">{parseFloat(userData.currentPoints.toFixed(4))}</span>
                                 
                                </div>
                                <div className="nk-sales-ck">
                                  <canvas
                                    className="sales-bar-chart"
                                    id="totalSubscription"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
                <div className="col-xxl-8">
                  <div className="card card-full">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">
                            <span className="me-2">Transactions</span>
                            
                          </h6>
                        </div>
                        <div className="card-tools">
                          <ul className="card-tools-nav">
                          
                            <li className="active">
                              <a href="#">
                                <span>All</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner p-0 border-top">
                      <div className="nk-tb-list nk-tb-orders">
                        <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col">
                            <span>Reference</span>
                          </div>
                          <div className="nk-tb-col tb-col-sm">
                            <span>Store</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span>Date</span>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span>Points</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Amount</span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="d-none d-sm-inline">Status</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                        {
                        pointsData.map((item, index) => (
                   <div key={index} className="nk-tb-item">
           
            <div className="nk-tb-col">
              <span className="tb-lead"><a href={`https://xmas.westgate.co.ke/receipt-details/${item.id}`}>#{item.id}</a></span>
            </div>
            <div className="nk-tb-col tb-col-sm">
              <span className="tb-lead">{item.shop}</span>
            </div>
            <div className="nk-tb-col tb-col-md">
              <span className="tb-sub">{item.uploaded_at}</span>
            </div>
            <div className="nk-tb-col tb-col-lg">
              <span className="tb-sub text-primary">{item.points}</span>
            </div>
            <div className="nk-tb-col">
              <span className="tb-sub tb-amount"><span>KES</span> {item.amount} </span>
            </div>
                            <div className="nk-tb-col">
                              <span className={`badge badge-dot badge-dot-xs ${item.pointStatus === '0' ? 'bg-warning' :
                                  item.pointStatus == 1 ? 'bg-success' :
                                    'bg-danger'}`}>
                                {item.pointStatus == 0 ? 'Pending' :
                                  item.pointStatus == 1 ? 'Approved' :
                                    'Rejected'}
                              </span>
                            </div>
           
          </div>
        )) }
                      
                      </div>
                    </div>
                    <div className="card-inner-sm border-top text-center d-sm-none">
                      <a href="#" className="btn btn-link btn-block">
                        See History
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  </div>
</div>
  )
}

export default UserPoints

