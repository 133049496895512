import React from "react"

class Footer extends React.Component{
    render(){
        return(
            <div className="nk-footer">
            <div className="container-xl wide-xl">
                <div className="nk-footer-wrap">
                    <div className="nk-footer-copyright">
                        © 2023 Westgate Shopping Mall
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default Footer