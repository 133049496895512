import React from "react"
class Navbar extends React.Component{
    render(){
        return(

            <div className="nk-header is-light nk-header-fixed is-light">
            <div className="container-xl wide-xl">
              <div className="nk-header-wrap">
                <div className="nk-menu-trigger d-xl-none ms-n1 me-3">
                  <a
                    href="#"
                    className="nk-nav-toggle nk-quick-nav-icon"
                    data-target="sidebarMenu"
                  >
                    <em className="icon ni ni-menu" />
                  </a>
                </div>
                <div className="nk-header-brand d-xl-none">
                  <a href="/dashboard" className="logo-link">
                    <img
                      className="logo-light logo-img"
                      src="/assets/images/wg_logo.png"
                      srcSet="/assets/images/wg_logo.png 2x"
                      alt="logo"
                    />
                    <img
                      className="logo-dark logo-img"
                      src="/assets/images/logo-dark.png"
                      srcSet="/assets/images/wg_logo.png 2x"
                      alt="logo-dark"
                    />
                  </a>
                </div>
                <div className="nk-header-menu is-light">
                  <div className="nk-header-menu-inner">
                    <ul className="nk-menu nk-menu-main">
                    <li className="nk-menu-item">
                        <a href="/dashboard" className="nk-menu-link">
                          <span className="nk-menu-text">Home</span>
                        </a>
                      </li>
                      <li className="nk-menu-item has-sub">
                        <a href="#" className="nk-menu-link nk-menu-toggle">
                          <span className="nk-menu-text">Customers</span>
                        </a>
                        <ul className="nk-menu-sub">
                          <li className="nk-menu-item">
                            <a href="/users" className="nk-menu-link">
                              <span className="nk-menu-text">All</span>
                            </a>
                          </li>
                          <li className="nk-menu-item">
                            <a href="/winners" className="nk-menu-link">
                              <span className="nk-menu-text">Winners</span>
                            </a>
                          </li>
                         
                        </ul>
                      </li>
                      <li className="nk-menu-item has-sub">
                        <a href="#" className="nk-menu-link nk-menu-toggle">
                          <span className="nk-menu-text">Receipts</span>
                        </a>
                        <ul className="nk-menu-sub">
                          <li className="nk-menu-item">
                            <a href="/all-transactions" className="nk-menu-link">
                              <span className="nk-menu-text">All</span>
                            </a>
                          </li>
                          <li className="nk-menu-item">
                            <a href="/pending-transactions" className="nk-menu-link">
                              <span className="nk-menu-text">Pending</span>
                            </a>
                          </li>
                          <li className="nk-menu-item">
                            <a
                              href="/approved-transactions"
                              className="nk-menu-link"
                            >
                              <span className="nk-menu-text">Approved</span>
                            </a>
                          </li>
                          <li className="nk-menu-item">
                            <a href="/rejected-transactions" className="nk-menu-link">
                              <span className="nk-menu-text">Rejected</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      
                    </ul>
                  </div>
                </div>
       
              </div>
            </div>
          </div>
          
        )
    }
}
export default Navbar