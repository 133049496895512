import React,{ useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import Navbar from "../partials/navbar"
import Header from "../partials/header"
import api from "../api/config"
import Footer from "../partials/footer"
const Dashboard = (props) => {
  const navigate = useNavigate();  // <-- Change this
    const [members, setMembers] = useState([])
    const [stores, setStores] = useState([])
    //point statistics
    const [points, setPoints] = useState({
      totalMembers: 0,
      totalApprovedPoints: 0,
      totalPendingPoints: 0,
      totalRejectedPoints: 0
  })
  const [error, setError] = useState(null)
    //point statistics
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!token) {
            navigate('/');  // <-- Change this
        }
        api.get('/api/topstores')
        .then(response => response.data)
        .then(data => setStores(data))
        .catch(error => console.error('Error fetching stores:', error))
        //api stats
        api.get('/api/allstats')
            .then(response => {
                // Set the data in state
                setPoints(response.data);
            })
            .catch(err => {
                // Handle any errors
                setError(err.message || 'An error occurred')
            });
        //api stats
    }, [navigate])

        return(
            <>
  <div className="nk-app-root">
    <div className="nk-main ">
      {/*Header*/}
    <Header/>
      <div className="nk-wrap ">
   {/*nav*/}
   <Navbar/>
        <div className="nk-content nk-content-fluid">
          <div className="container-xl wide-xl">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Christmas Campaign Management
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p>Welcome to Westgate Shopping Mall Campaign Administration</p>
                    </div>
                  </div>
                  <div className="nk-block-head-content">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand me-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v" />
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li>
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                              data-bs-toggle="dropdown"
                            >
                              <em className="icon ni ni-plus" />
                              <span>
                                <span className="d-md-none">Add</span>
                                <span className="d-none d-md-block">
                                  Add Campaign
                                </span>
                              </span>
                            </a>
                          </li>
                          <li className="nk-block-tools-opt">
                            <a href="#" className="btn btn-primary">
                              <em className="icon ni ni-reports" />
                              <span>Reports</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-lg-3 col-sm-6">
                    <a href="/users">
                    <div className="card h-100 bg-success">
                      <div className="nk-cmwg nk-cmwg1">
                        <div className="card-inner pt-3">
                          <div className="d-flex justify-content-between">
                            <div className="flex-item">
                              <div className="text-white d-flex flex-wrap">
                                <span className="fs-2 me-1">{points.totalMembers}</span>
                                {/*<span className="align-self-end fs-14px pb-1">
                                  <em className="icon ni ni-arrow-long-up" />
                                  12.4%
                                </span> */}
                              </div>
                              <h6 className="text-white">All Shoppers</h6>
                            </div>
                            <div className="card-tools me-n1">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-toggle btn btn-icon btn-sm btn-trigger on-dark"
                                  data-bs-toggle="dropdown"
                                >
                                  <em className="icon ni ni-more-v" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <a href="#" className="active">
                                        <span>15 Days</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span>30 Days</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span>3 Months</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </div></a>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                  <a href="/approved-transactions">
                    <div className="card h-100 bg-info">
                      <div className="nk-cmwg nk-cmwg1">
                        <div className="card-inner pt-3">
                          <div className="d-flex justify-content-between">
                            <div className="flex-item">
                              <div className="text-white d-flex flex-wrap">
                                <span className="fs-2 me-1">{points.totalApprovedPoints}</span>
                                {/*<span className="align-self-end fs-14px pb-1">
                                  <em className="icon ni ni-arrow-long-up" />
                                  12.4%
                                </span> */}
                              </div>
                              <h6 className="text-white">Approved Points</h6>
                            </div>
                            <div className="card-tools me-n1">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-toggle btn btn-icon btn-sm btn-trigger on-dark"
                                  data-bs-toggle="dropdown"
                                >
                                  <em className="icon ni ni-more-v" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <a href="#" className="active">
                                        <span>15 Days</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span>30 Days</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span>3 Months</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </a>
                  </div> 
                 
                  <div className="col-lg-3 col-sm-6">
                  <a href="/pending-transactions">
                    <div className="card h-100 bg-warning">
                      <div className="nk-cmwg nk-cmwg1">
                        <div className="card-inner pt-3">
                          <div className="d-flex justify-content-between">
                            <div className="flex-item">
                              <div className="text-white d-flex flex-wrap">
                                <span className="fs-2 me-1">{points.totalPendingPoints}</span>
                                {/*<span className="align-self-end fs-14px pb-1">
                                  <em className="icon ni ni-arrow-long-up" />
                                  12.4%
                                </span> */}
                              </div>
                              <h6 className="text-white">Pending points</h6>
                            </div>
                            <div className="card-tools me-n1">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-toggle btn btn-icon btn-sm btn-trigger on-dark"
                                  data-bs-toggle="dropdown"
                                >
                                  <em className="icon ni ni-more-v" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <a href="#" className="active">
                                        <span>15 Days</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span>30 Days</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span>3 Months</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                    </a>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                  <a href="/rejected-transactions">
                    <div className="card h-100 bg-danger">
                      <div className="nk-cmwg nk-cmwg1">
                        <div className="card-inner pt-3">
                          <div className="d-flex justify-content-between">
                            <div className="flex-item">
                              <div className="text-white d-flex flex-wrap">
                                <span className="fs-2 me-1">{points.totalRejectedPoints}</span>
                               {/* <span className="align-self-end fs-14px pb-1">
                                  <em className="icon ni ni-arrow-long-up" />
                                  12.4%
        </span>  */}
                              </div>
                              <h6 className="text-white">Rejected points</h6>
                            </div>
                            <div className="card-tools me-n1">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-toggle btn btn-icon btn-sm btn-trigger on-dark"
                                  data-bs-toggle="dropdown"
                                >
                                  <em className="icon ni ni-more-v" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <a href="#" className="active">
                                        <span>15 Days</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span>30 Days</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <span>3 Months</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    </a>
                  </div>
                  <div className="col-xxl-8 col-lg-7">
                    <div className="card card-full">
                      <div className="card-inner">
                        <div className="card-title-group">
                          <div className="card-title">
                            <h6 className="title">Performance Overview</h6>
                          </div>
                          <div className="card-tools">
                            <ul className="card-tools-nav">
                              <li>
                                <a href="#">
                                  <span>Week</span>
                                </a>
                              </li>
                              <li className="active">
                                <a href="#">
                                  <span>Month</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card-inner pt-0">
                        <ul className="d-flex justify-content-center flex-wrap gx-3 mb-2">
                          <li className="align-center">
                            <span className="dot" data-bg="#733AEA" />
                            <span className="ms-1">Social</span>
                          </li>
                          <li className="align-center">
                            <span className="dot" data-bg="#0FCA7A" />
                            <span className="ms-1">Email</span>
                          </li>
                          <li className="align-center">
                            <span className="dot" data-bg="#F2426E" />
                            <span className="ms-1">Courses</span>
                          </li>
                          <li className="align-center">
                            <span className="dot" data-bg="#FD9722" />
                            <span className="ms-1">Google Ads</span>
                          </li>
                        </ul>
                        <div className="nk-cmwg2-ck">
                          <canvas
                            className="campaign-line-chart-s2"
                            id="performanceOverview"
                          />
                        </div>
                        <div className="chart-label-group ms-5">
                          <div className="chart-label">03 Jan</div>
                          <div className="chart-label d-none d-sm-block">
                            10 Jan
                          </div>
                          <div className="chart-label d-none d-sm-block">
                            17 Jan
                          </div>
                          <div className="chart-label d-none d-sm-block">
                            24 Jan
                          </div>
                          <div className="chart-label">31 Jan</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-lg-5">
                    <div className="card card-full">
                      <div className="card-inner">
                        <div className="card-title-group">
                          <div className="card-title">
                            <h6 className="title">Campaign Prizes</h6>
                          </div>
                          <div className="card-tools">
                            <a href="#" className="link">
                              View All
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-inner pt-0">
                        <ul className="gy-4">
                          <li className="justify-between align-center border-bottom border-0 border-dashed">
                            <div className="align-center">
                              <div className="user-avatar sq bg-transparent">
                                <img
                                  src="assets/images/voi.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="ms-2">
                                <div className="lead-text">Voi Wildlife Lodge </div>
                                <div className="sub-text"><small>2 Voi Wildlife Holiday Vouchers<br/>
	2 Manyatta Camp Holiday Vouchers<br/>
3 Maneaters Lodge Holiday Vouchers
</small></div>
                              </div>
                            </div>
                            <div className="align-center">
                              <div className="sub-text me-2">Available</div>
                              <div className="progress rounded-pill w-80px">
                                <div
                                  className="progress-bar bg-success rounded-pill"
                                  data-progress={100}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="justify-between align-center border-bottom border-0 border-dashed">
                            <div className="align-center">
                              <div className="user-avatar sq bg-transparent">
                                <img
                                  src="assets/images/jetlak.png"
                                  alt=""
                                />
                              </div>
                              <div className="ms-2">
                                <div className="lead-text">Jetlak Foods </div>
                                <div className="sub-text"><small>Jetlak Foods	Assorted Beverages worth KES 350,000/=</small></div>
                              </div>
                            </div>
                            <div className="align-center">
                              <div className="sub-text me-2">Available</div>
                              <div className="progress rounded-pill w-80px">
                                <div
                                  className="progress-bar bg-success rounded-pill"
                                  data-progress={100}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="justify-between align-center border-bottom border-0 border-dashed">
                            <div className="align-center">
                              <div className="user-avatar sq bg-transparent">
                                <img
                                  src="assets/images/hoodie.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="ms-2">
                                <div className="lead-text">Westgate Merchandise</div>
                                <div className="sub-text"><small>Umbrellas,Mouse Pads,T-shirts etc</small></div>
                              </div>
                            </div>
                            <div className="align-center">
                              <div className="sub-text me-2">Available</div>
                              <div className="progress rounded-pill w-80px">
                                <div
                                  className="progress-bar bg-success rounded-pill"
                                  data-progress={100}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="justify-between align-center border-bottom border-0 border-dashed">
                            <div className="align-center">
                              <div className="user-avatar sq bg-transparent">
                                <img
                                  src="assets/images/chicken.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="ms-2">
                                <div className="lead-text">Simbisa Brands </div>
                                <div className="sub-text"><small>26 Vouchers
                                  </small></div>
                              </div>
                            </div>
                            <div className="align-center">
                              <div className="sub-text me-2">Available</div>
                              <div className="progress rounded-pill w-80px">
                                <div
                                  className="progress-bar bg-success rounded-pill"
                                  data-progress={100}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="justify-between align-center">
                            <div className="align-center">
                              <div className="user-avatar sq bg-transparent">
                                <img
                                  src="assets/images/q.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="ms-2">
                                <div className="lead-text">TBC </div>
                                <div className="sub-text"><small>Sonna Shoppe,Panari Hotels,<br>
                                </br> Liberty Eagle – Yves Rocher,Diamond Watch Company</small></div>
                              </div>
                            </div>
                            <div className="align-center">
                              <div className="sub-text me-2">Pending</div>
                              <div className="progress rounded-pill w-80px">
                                <div
                                  className="progress-bar bg-warning rounded-pill"
                                  data-progress={100}
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-md-6">
                    <div className="card card-full">
                      <div className="card-inner">
                        <div className="card-title-group">
                          <div className="card-title">
                            <h6 className="title">Top stores</h6>
                          </div>
                          <div className="card-tools me-n1 mt-n1">
                            <div className="dropdown">
                              <a
                                href="#"
                                className="dropdown-toggle btn btn-icon btn-trigger"
                                data-bs-toggle="dropdown"
                              >
                                <em className="icon ni ni-more-h" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <a href="#" className="active">
                                      <span>15 Days</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <span>30 Days</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <span>3 Months</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                                <div className="card-inner pt-0">
                                  <ul className="my-n2">
                                    {stores.map((store, index) => (
                                      <li
                                        key={index}
                                        className="align-center justify-between py-1 gx-1 border-bottom border-0 border-dashed"
                                        >
                                        <div className="lead-text">{store.name}</div>
                                        <div className="sub-text">{store.shoppers}</div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-md-6">
                    <div className="card card-full">
                      <div className="card-inner">
                        <div className="card-title-group">
                          <div className="card-title">
                            <h6 className="title">Web conv. rate</h6>
                          </div>
                          <div className="card-tools me-n1 mt-n1">
                            <div className="dropdown">
                              <a
                                href="#"
                                className="dropdown-toggle btn btn-icon btn-trigger"
                                data-bs-toggle="dropdown"
                              >
                                <em className="icon ni ni-more-h" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <a href="#" className="active">
                                      <span>15 Days</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <span>30 Days</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <span>3 Months</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-inner pt-0">
                        <div className="">
                          <span className="fs-2 lh-1 mb-1 text-head">
                            3.86%
                          </span>
                          <div className="fs-14px">This month</div>
                        </div>
                        <div className="nk-cmwg4-ck mt-4">
                          <canvas
                            className="campaign-line-chart-s3"
                            id="webConvRate"
                          />
                        </div>
                        <div className="chart-label-group ms-5 mt-0">
                          <div className="chart-label">Jan</div>
                          <div className="chart-label d-none d-sm-block">
                            Feb
                          </div>
                          <div className="chart-label d-none d-sm-block">
                            Mar
                          </div>
                          <div className="chart-label d-none d-sm-block">
                            Apr
                          </div>
                          <div className="chart-label d-none d-sm-block">
                            May
                          </div>
                          <div className="chart-label">Jun</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  </div>
 
</>

        )
    }
export default Dashboard