import React from "react"
class Header extends React.Component{
    render(){
        return(
            <div
  className="nk-sidebar is-light nk-sidebar-fixed "
  data-content="sidebarMenu"
>
  <div className="nk-sidebar-element nk-sidebar-head">
    <div className="nk-sidebar-brand">
      <a href="/dashboard" className="logo-link nk-sidebar-logo">
        <img
          className="logo-light logo-img"
          src="/assets/images/wg_logo.png"
          srcSet="./assets/images/wg_logo.png 2x"
          alt="logo"
        />
        <img
          className="logo-dark logo-img"
          src="/assets/images/wg_logo.png"
          srcSet="/assets/images/wg_logo.png 2x"
          alt="logo-dark"
        />
        <img
          className="logo-small logo-img logo-img-small"
          src="/assets/images/wg_logo.png"
          srcSet="/assets/images/wg_logo.png 2x"
          alt="logo-small"
        />
      </a>
    </div>
    <div className="nk-menu-trigger me-n2">
      <a
        href="#"
        className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
        data-target="sidebarMenu"
      >
        <em className="icon ni ni-arrow-left" />
      </a>
    </div>
  </div>
  <div className="nk-sidebar-element">
    <div className="nk-sidebar-content">
      <div className="nk-sidebar-menu" data-simplebar="">
        <ul className="nk-menu">
          <li className="nk-menu-heading">
            <h6 className="overline-title text-primary-alt">
              Home
            </h6>
          </li>
          <li className="nk-menu-item">
            <a href="/dashboard" className="nk-menu-link">
              <span className="nk-menu-icon">
                <em className="icon ni ni-bag" />
              </span>
              <span className="nk-menu-text">Dashboard</span>
              <span className="nk-menu-badge">20</span>
            </a>
          </li>
         
          <li className="nk-menu-heading">
            <h6 className="overline-title text-primary-alt">Campaign Points</h6>
          </li>
          <li className="nk-menu-item has-sub">
            <a href="#" className="nk-menu-link nk-menu-toggle">
              <span className="nk-menu-icon">
                <em className="icon ni ni-tile-thumb" />
              </span>
              <span className="nk-menu-text">Stores</span>
            </a>
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <a href="/stores" className="nk-menu-link">
                  <span className="nk-menu-text">Participants</span>
                </a>
              </li>
              <li className="nk-menu-item">
                <a href="/offline-stores" className="nk-menu-link">
                  <span className="nk-menu-text">Non-participants</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li className="nk-menu-item has-sub">
            <a href="#" className="nk-menu-link nk-menu-toggle">
              <span className="nk-menu-icon">
                <em className="icon ni ni-user-list" />
              </span>
              <span className="nk-menu-text">Customers</span>
            </a>
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <a href="/users" className="nk-menu-link">
                  <span className="nk-menu-text">Customer List</span>
                </a>
              </li>
              <li className="nk-menu-item">
                <a href="winners" className="nk-menu-link">
                  <span className="nk-menu-text">Winners</span>
                </a>
              </li>
            </ul>
          </li>
          <li className="nk-menu-item has-sub">
            <a href="#" className="nk-menu-link nk-menu-toggle">
              <span className="nk-menu-icon">
                <em className="icon ni ni-tranx" />
              </span>
              <span className="nk-menu-text">Transactions</span>
            </a>
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <a href="all-transactions" className="nk-menu-link">
                  <span className="nk-menu-text">All</span>
                </a>
              </li>
              <li className="nk-menu-item">
                <a
                  href="approved-transactions"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Approved</span>
                </a>
              </li>
              <li className="nk-menu-item">
                <a
                  href="pending-transactions"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Pending</span>
                </a>
              </li>
              <li className="nk-menu-item">
                <a
                  href="rejected-transactions"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">Rejected</span>
                </a>
              </li>
            </ul>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
</div>

        )
    }
}
export default Header