import React, { useState, useEffect } from "react"
import Navbar from "../partials/navbar"
import Header from "../partials/header"
import api from "../api/config"
import Footer from "../partials/footer"
import { classNames } from 'primereact/utils'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { Tag } from 'primereact/tag'
import { TriStateCheckbox } from 'primereact/tristatecheckbox'
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api'
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css"

import { useNavigate } from 'react-router-dom'
import "primereact/resources/themes/lara-light-indigo/theme.css"               

const PendingTransactionsList = (props) => {
  const navigate = useNavigate()  // <-- Change this
    const [allTransactions, setTransactions] = useState([])
    

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');  // <-- Change this
        } else {
            api.get('/api/transactions/pending')
                .then(response => response.data)
                .then(data => setTransactions(data))
                .catch(error => console.error('Error fetching pending transactions:', error))
        }
    }, [navigate]);

    return (
        <div className="nk-app-root">
            <div className="nk-main">
                <Header />
                <div className="nk-wrap">
                    <Navbar />
                    <div className="nk-content nk-content-fluid">
                        <div className="container-xl wide-xl">
                            <div className="nk-content-body">
                                <div className="components-preview wide-xl mx-auto">
                                    <div className="nk-block-head nk-block-head-lg wide-sm">
                                        <div className="nk-block-head-content">
                                            <div className="nk-block-head-sub">
                                                <a className="back-to" href="/dashboard">
                                                    <em className="icon ni ni-arrow-left" />
                                                    <span>Home</span>
                                                </a>
                                            </div>
                                            <h2 className="nk-block-title fw-normal">
                                               Pending Campaign Transactions
                                            </h2>
                                            <div className="nk-block-des">
                                                <p className="lead">
                                                  All pending Uploaded receipts in Christmas campaign
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block nk-block-lg">
                                        <div className="card card-preview">
                                            <div className="card-inner">
                                                <PrimeReactProvider>
                                                    <DataTable 
                                                        value={allTransactions} 
                                                        paginator 
                                                        rows={10} 
                                                        dataKey="id" 
                                                        reorderableRows={true}
                                                        onRowClick={(e) => window.location.href = `/receipt-details/${e.data.id}`}
                                                    >
                                                        <Column field="customer" header="Customer Name" filter filterPlaceholder="Search by Customer name" style={{ minWidth: '12rem' }}  />
                                                        <Column field="amount" header="Amount in KES" filter filterPlaceholder="Search by amount" style={{ minWidth: '15rem' }}  />
                                                        <Column field="shop" header="Shop Id" filter filterPlaceholder="Search by shop Id" style={{ minWidth: '12rem' }} />
                                                        <Column field="points" header="Points" filter filterPlaceholder="Search by points Earned" style={{ minWidth: '12rem' }}  />
                                                        <Column field="pointStatus" header="Status" filter filterPlaceholder="Search by Status" style={{ minWidth: '12rem' }} />
                                        
                                                    </DataTable>
                                                </PrimeReactProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default PendingTransactionsList