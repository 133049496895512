import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import api from "../api/config"
import Header from "../partials/header"
import Navbar from "../partials/navbar"
import Footer from "../partials/footer"
function StoreDetails() {
  const { storeId } = useParams();
  const [storeData, setstoreData] = useState(null)

  useEffect(() => {
    api.get(`/api/storeinfo/${storeId}`)
      .then(response => {
        setstoreData(response.data)
      })
      .catch(error => {
        console.error('Error fetching store data:', error)
      })
  }, [storeId])

  if (!storeData) {
    return <div>not found</div>
  }
console.log(storeData)
  return (
<div className="nk-app-root">
  <div className="nk-main ">
  <Header />
    <div className="nk-wrap ">
    <Navbar />
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title">{storeData.name} Shoppers Overview</h3>
                  <div className="nk-block-des text-soft">
                    <p>Store information</p>
                  </div>
                </div>
                <div className="nk-block-head-content">
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand me-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-more-v" />
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          <div className="drodown">
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                              data-bs-toggle="dropdown"
                            >
                              <em className="d-none d-sm-inline icon ni ni-calender-date" />
                              <span>
                                <span className="d-none d-md-inline">Last</span>{" "}
                                30 Days
                              </span>
                              <em className="dd-indc icon ni ni-chevron-right" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a href="#">
                                    <span>Last 30 Days</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span>Last 6 Months</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span>Last 1 Years</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="nk-block-tools-opt">
                          <a href="/stores" className="btn btn-primary">
                            <em className="icon ni ni-arrow-left" />
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-block">
              <div className="row g-gs">
                <div className="col-xxl-7">
                  <div className="row g-gs">
                    <div className="col-lg-7 col-xxl-12">
                      <div className="card">
                        <div className="card-inner">
                          <div className="card-title-group align-start mb-2">
                            <div className="card-title">
                              <h6 className="title">Store Information</h6>
                              <p>Personal information</p>
                            </div>
                            <div className="card-tools">
                              <em
                                className="card-hint icon ni ni-help-fill"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Revenue from subscription"
                              />
                            </div>
                          </div>
                          <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
                            <div className="nk-sale-data-group flex-md-nowrap g-4">
                              <div className="nk-sale-data">
                                <span className="amount">
                                  14,299.59
                                  <span className="change down text-danger">
                                    <em className="icon ni ni-arrow-long-down" />
                                    16.93%
                                  </span>
                                </span>
                                <span className="sub-title">This Month</span>
                              </div>
                              <div className="nk-sale-data">
                                <span className="amount">
                                  7,299.59
                                  <span className="change up text-success">
                                    <em className="icon ni ni-arrow-long-up" />
                                    4.26%
                                  </span>
                                </span>
                                <span className="sub-title">This Week</span>
                              </div>
                            </div>
                            <div className="nk-sales-ck sales-revenue">
                              <canvas
                                className="sales-bar-chart"
                                id="salesRevenue"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-xxl-12">
                      <div className="row g-gs">
                        <div className="col-sm-6 col-lg-12 col-xxl-6">
                          <div className="card">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">
                                   Total Amount Spend
                                  </h6>
                                </div>
                                <div className="card-tools">
                                  <em
                                    className="card-hint icon ni ni-help-fill"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Total active subscription"
                                  />
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">9.69K</span>
                                  <span className="sub-title">
                                    <span className="change down text-danger">
                                      <em className="icon ni ni-arrow-long-down" />
                                      1.93%
                                    </span>
                                    since last month
                                  </span>
                                </div>
                                <div className="nk-sales-ck">
                                  <canvas
                                    className="sales-bar-chart"
                                    id="activeSubscription"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-12 col-xxl-6">
                          <div className="card">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Points Earned</h6>
                                </div>
                                <div className="card-tools">
                                  <em
                                    className="card-hint icon ni ni-help-fill"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Daily Avg. subscription"
                                  />
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">346.2</span>
                                  <span className="sub-title">
                                    <span className="change up text-success">
                                      <em className="icon ni ni-arrow-long-up" />
                                      2.45%
                                    </span>
                                    since last week
                                  </span>
                                </div>
                                <div className="nk-sales-ck">
                                  <canvas
                                    className="sales-bar-chart"
                                    id="totalSubscription"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
                <div className="col-xxl-8">
                  <div className="card card-full">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">
                            <span className="me-2">Transaction</span>{" "}
                            <a href="#" className="link d-none d-sm-inline">
                              See History
                            </a>
                          </h6>
                        </div>
                        <div className="card-tools">
                          <ul className="card-tools-nav">
                            <li>
                              <a href="#">
                                <span>Paid</span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <span>Pending</span>
                              </a>
                            </li>
                            <li className="active">
                              <a href="#">
                                <span>All</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner p-0 border-top">
                      <div className="nk-tb-list nk-tb-orders">
                        <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col">
                            <span>Order No.</span>
                          </div>
                          <div className="nk-tb-col tb-col-sm">
                            <span>Store</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span>Date</span>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span>Points</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>Amount</span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="d-none d-sm-inline">Status</span>
                          </div>
                          <div className="nk-tb-col">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <a href="#">#95954</a>
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-sm">
                            <div className="user-card">
                              
                              <div className="user-name">
                                <span className="tb-lead">
                                  Abu Bin Ishtiyak
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="tb-sub">02/11/2020</span>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span className="tb-sub text-primary">
                              SUB-2309232
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub tb-amount">
                              4,596.75
                              <span> KES</span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="badge badge-dot badge-dot-xs bg-success">
                              Paid
                            </span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-action">
                            <div className="dropdown">
                              <a
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                data-bs-toggle="dropdown"
                              >
                                <em className="icon ni ni-more-h" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                                <ul className="link-list-plain">
                                  <li>
                                    <a href="#">View</a>
                                  </li>
                                  
                                  <li>
                                    <a href="#">Print</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <a href="#">#95850</a>
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-sm">
                            <div className="user-card">
                             
                              <div className="user-name">
                                <span className="tb-lead">Desiree Edwards</span>
                              </div>
                            </div>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="tb-sub">02/02/2020</span>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span className="tb-sub text-primary">
                              SUB-2309154
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub tb-amount">
                              596.75
                              <span> KES</span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="badge badge-dot badge-dot-xs bg-danger">
                              Canceled
                            </span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-action">
                            <div className="dropdown">
                              <a
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                data-bs-toggle="dropdown"
                              >
                                <em className="icon ni ni-more-h" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                                <ul className="link-list-plain">
                                  <li>
                                    <a href="#">View</a>
                                  </li>
                                  <li>
                                    <a href="#">Print</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <a href="#">#95812</a>
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-sm">
                            <div className="user-card">
                              
                              <div className="user-name">
                                <span className="tb-lead">Blanca Schultz</span>
                              </div>
                            </div>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="tb-sub">02/01/2020</span>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span className="tb-sub text-primary">
                              SUB-2309143
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub tb-amount">
                              199.99
                              <span> KES</span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="badge badge-dot badge-dot-xs bg-success">
                              Paid
                            </span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-action">
                            <div className="dropdown">
                              <a
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                data-bs-toggle="dropdown"
                              >
                                <em className="icon ni ni-more-h" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                                <ul className="link-list-plain">
                                  <li>
                                    <a href="#">View</a>
                                  </li>
                                  <li>
                                    <a href="#">Print</a>
                                  </li>
                                 
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <a href="#">#95256</a>
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-sm">
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">Naomi Lawrence</span>
                              </div>
                            </div>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="tb-sub">01/29/2020</span>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span className="tb-sub text-primary">
                              SUB-2305684
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub tb-amount">
                              1099.99
                              <span> KES</span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="badge badge-dot badge-dot-xs bg-success">
                              Paid
                            </span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-action">
                            <div className="dropdown">
                              <a
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                data-bs-toggle="dropdown"
                              >
                                <em className="icon ni ni-more-h" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                                <ul className="link-list-plain">
                                  <li>
                                    <a href="#">View</a>
                                  </li>
                                  <li>
                                    <a href="#">Print</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <a href="#">#95135</a>
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-sm">
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">Cassandra Hogan</span>
                              </div>
                            </div>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="tb-sub">01/29/2020</span>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span className="tb-sub text-primary">
                              SUB-2305564
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub tb-amount">
                              1099.99
                              <span> KES</span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="badge badge-dot badge-dot-xs bg-warning">
                              Due
                            </span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-action">
                            <div className="dropdown">
                              <a
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                data-bs-toggle="dropdown"
                              >
                                <em className="icon ni ni-more-h" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-end dropdown-menu-xs">
                                <ul className="link-list-plain">
                                  <li>
                                    <a href="#">View</a>
                                  </li>
                                  <li>
                                    <a href="#">Print</a>
                                  </li>
                                  
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner-sm border-top text-center d-sm-none">
                      <a href="#" className="btn btn-link btn-block">
                        See History
                      </a>
                    </div>
                  </div>
                </div>
          
                <div className="col-lg-6 col-xxl-4">
                  <div className="card h-100">
                    <div className="card-inner border-bottom">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h6 className="title">Activity Log</h6>
                        </div>
                        <div className="card-tools">
                          <a href="#" className="link">
                            View All
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner">
                      <div className="timeline">
                        <h6 className="timeline-head">Christmas Campaign 2023</h6>
                        <ul className="timeline-list">
                          <li className="timeline-item">
                            <div className="timeline-status bg-primary is-outline" />
                            <div className="timeline-date">
                              1st Nov <em className="icon ni ni-alarm-alt" />
                            </div>
                            <div className="timeline-data">
                              <h6 className="timeline-title">
                                Submitted KYC Application
                              </h6>
                              <div className="timeline-des">
                                <p>Submitted KYC form via Mobile app</p>
                                <span className="time">09:30am</span>
                              </div>
                            </div>
                          </li>
                          <li className="timeline-item">
                            <div className="timeline-status bg-primary" />
                            <div className="timeline-date">
                              1st Nov <em className="icon ni ni-alarm-alt" />
                            </div>
                            <div className="timeline-data">
                              <h6 className="timeline-title">
                                KYC documentation Approved
                              </h6>
                              <div className="timeline-des">
                                <p>Verified by Naserian</p>
                                <span className="time">10:30am</span>
                              </div>
                            </div>
                          </li>
                          <li className="timeline-item">
                            <div className="timeline-status bg-pink" />
                            <div className="timeline-date">
                              3 Nov <em className="icon ni ni-alarm-alt" />
                            </div>
                            <div className="timeline-data">
                              <h6 className="timeline-title">
                               Win
                              </h6>
                              <div className="timeline-des">
                                <p>Won Westgate Merchandise</p>
                                <span className="time">2:35pm</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  </div>
</div>
  )
}

export default StoreDetails


