import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import api from "../api/config"
import Header from "../partials/header"
import Navbar from "../partials/navbar"
import Footer from "../partials/footer"
function ReceiptDetails() {
  const { receiptId } = useParams();
  const [ReceiptData, setReceiptData] = useState(null)
  const [selectedAction, setSelectedAction] = useState('')
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Construct the form data
    const formData = {
      receiptId: receiptId,
      action: selectedAction
    }
    api.post('/api/pointaction', formData)
      .then(response => {
        console.log('Form submitted successfully', response);
        // Handle successful submission here
      })
      .catch(error => {
        console.error('There was an error submitting the form', error)
        // Handle errors here
      })
      window.location.replace("/pending-transactions")
  }

  const handleSelectChange = (event) => {
    setSelectedAction(event.target.value);
}
  useEffect(() => {
    api.get(`/api/receiptinfo/${receiptId}`)
      .then(response => {
        setReceiptData(response.data)
      })
      .catch(error => {
        console.error('Error fetching user data:', error)
      })
  }, [receiptId])

  if (!ReceiptData) {
    return <div>not found</div>
  }
//console.log(userData)
  return (
<div className="nk-app-root">
  <div className="nk-main ">
  <Header />
    <div className="nk-wrap ">
    <Navbar />
      <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title">{ReceiptData.customer} receipt approval</h3>
                  <div className="nk-block-des text-soft">
                    <p>Receipt information</p>
                  </div>
                </div>
                <div className="nk-block-head-content">
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand me-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-more-v" />
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          <div className="drodown">
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                              data-bs-toggle="dropdown"
                            >
                              <em className="d-none d-sm-inline icon ni ni-calender-date" />
                              <span>
                                <span className="d-none d-md-inline">Last</span>{" "}
                                30 Days
                              </span>
                              <em className="dd-indc icon ni ni-chevron-right" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a href="#">
                                    <span>Last 30 Days</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span>Last 6 Months</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <span>Last 1 Years</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="nk-block-tools-opt">
                          <a href="/pending-transactions" className="btn btn-primary">
                            <em className="icon ni ni-arrow-left" />
                            <span>Back</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-block">
              <div className="row g-gs">
                <div className="col-xxl-7">
                  <div className="row g-gs">
                    <div className="col-lg-7 col-xxl-12">
                      <div className="card">
                        <div className="card-inner">
                          <div className="card-title-group align-start mb-2">
                            <div className="card-title">
                              <h6 className="title">Receipt Image</h6>
                              <p>(Raw Image)</p>
                            </div>
                            <div className="card-tools">
                              <em
                                className="card-hint icon ni ni-help-fill"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Revenue from subscription"
                              />
                            </div>
                          </div>
                          <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
                            <div className="nk-sale-data-group flex-md-nowrap g-4">
                            <img src={`https://xmas.westgate.co.ke/api/uploads/${ReceiptData.image}`} alt="receipt image" />
                            </div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit}>
      <input type="hidden" value={receiptId} name="receiptId"/>
      <select 
        className="form-control form-control-lg" 
        name="action" 
        required 
        value={selectedAction}
        onChange={handleSelectChange}
      >
        <option hidden value="">[SELECT ACTION]</option>
        <option value="1">Approve</option>
        <option value="2">Reject</option>
      </select>
      <br></br>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
                   
                  </div>
                </div>
            <img></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  </div>
</div>
  )
}

export default ReceiptDetails
